function gallery() {
    var full = $('.cardGallery__fullsize'),
        thumb = $('.cardGallery__thumbnails');

    full.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.cardGallery__thumbnails',
        arrows: false
    });

    thumb.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: '.cardGallery__fullsize'
    })
}

function cardTabs() {
    var btn = $('.cardTabs__btn'),
        tab = $('.cardTabs__tab');

    btn.on('click', function () {
        var that = $(this),
            index = btn.index(that);

        btn.removeClass('active');
        tab.removeClass('active');
        that.addClass('active');
        tab.eq(index).addClass('active');
    })
}

$(document).ready(function () {
    gallery();
    cardTabs();
});